import _logger from "./logger";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Logger = void 0;
var logger_1 = _logger;
Object.defineProperty(exports, "Logger", {
  enumerable: true,
  get: function () {
    return logger_1.Logger;
  }
});
export default exports;