import _console from "./console.logger";
var exports = {};
var _a;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Logger = void 0;
const console_logger_1 = _console;
const DEFAULT_LOG_LEVELS = ["verbose", "debug", "log", "warn", "error", "fatal"];
const DEFAULT_LOGGER = typeof process === "object" && process.env.NEAR_NO_LOGS ? undefined : new console_logger_1.ConsoleLogger(DEFAULT_LOG_LEVELS);
/**
 * Used to log the library messages
 */
class Logger {
  static error(message, ...optionalParams) {
    var _b;
    (_b = this.instanceRef) === null || _b === void 0 ? void 0 : _b.error(message, ...optionalParams);
  }
  /**
   * Write a 'log' level log.
   */
  static log(message, ...optionalParams) {
    var _b;
    (_b = this.instanceRef) === null || _b === void 0 ? void 0 : _b.log(message, ...optionalParams);
  }
  /**
   * Write a 'warn' level log.
   */
  static warn(message, ...optionalParams) {
    var _b;
    (_b = this.instanceRef) === null || _b === void 0 ? void 0 : _b.warn(message, ...optionalParams);
  }
  /**
   * Write a 'debug' level log.
   */
  static debug(message, ...optionalParams) {
    var _b, _c;
    (_c = (_b = this.instanceRef) === null || _b === void 0 ? void 0 : _b.debug) === null || _c === void 0 ? void 0 : _c.call(_b, message, ...optionalParams);
  }
  /**
   * Write a 'verbose' level log.
   */
  static verbose(message, ...optionalParams) {
    var _b, _c;
    (_c = (_b = this.instanceRef) === null || _b === void 0 ? void 0 : _b.verbose) === null || _c === void 0 ? void 0 : _c.call(_b, message, ...optionalParams);
  }
  static fatal(message, ...optionalParams) {
    var _b, _c;
    (_c = (_b = this.instanceRef) === null || _b === void 0 ? void 0 : _b.fatal) === null || _c === void 0 ? void 0 : _c.call(_b, message, ...optionalParams);
  }
}
exports.Logger = Logger;
_a = Logger;
Logger.instanceRef = DEFAULT_LOGGER;
Logger.overrideLogger = logger => {
  _a.instanceRef = logger;
};
export default exports;