import _logger from "../logger";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.logWarning = void 0;
const logger_1 = _logger;
/** @deprecated */
function logWarning(...args) {
  const [message, ...optionalParams] = args;
  logger_1.Logger.warn(message, ...optionalParams);
}
exports.logWarning = logWarning;
export default exports;