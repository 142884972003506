var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DEFAULT_FUNCTION_CALL_GAS = void 0;
// Default amount of gas to be sent with the function calls. Used to pay for the fees
// incurred while running the contract execution. The unused amount will be refunded back to
// the originator.
// Due to protocol changes that charge upfront for the maximum possible gas price inflation due to
// full blocks, the price of max_prepaid_gas is decreased to `300 * 10**12`.
// For discussion see https://github.com/nearprotocol/NEPs/issues/67
exports.DEFAULT_FUNCTION_CALL_GAS = BigInt("30000000000000");
export default exports;