import _errors from "./errors";
import _logger from "./logger";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.printTxOutcomeLogs = exports.printTxOutcomeLogsAndFailures = void 0;
const errors_1 = _errors;
const logger_1 = _logger;
/**
 * Parse and print details from a query execution response
 * @param params
 * @param params.contractId ID of the account/contract which made the query
 * @param params.outcome the query execution response
 */
function printTxOutcomeLogsAndFailures({
  contractId,
  outcome
}) {
  const flatLogs = [outcome.transaction_outcome, ...outcome.receipts_outcome].reduce((acc, it) => {
    const isFailure = typeof it.outcome.status === "object" && typeof it.outcome.status.Failure === "object";
    if (it.outcome.logs.length || isFailure) {
      return acc.concat({
        receiptIds: it.outcome.receipt_ids,
        logs: it.outcome.logs,
        failure: typeof it.outcome.status === "object" && it.outcome.status.Failure !== undefined ? (0, errors_1.parseRpcError)(it.outcome.status.Failure) : null
      });
    } else {
      return acc;
    }
  }, []);
  for (const result of flatLogs) {
    logger_1.Logger.log(`Receipt${result.receiptIds.length > 1 ? "s" : ""}: ${result.receiptIds.join(", ")}`);
    printTxOutcomeLogs({
      contractId,
      logs: result.logs,
      prefix: "\t"
    });
    if (result.failure) {
      logger_1.Logger.warn(`\tFailure [${contractId}]: ${result.failure}`);
    }
  }
}
exports.printTxOutcomeLogsAndFailures = printTxOutcomeLogsAndFailures;
/**
 * Format and print log output from a query execution response
 * @param params
 * @param params.contractId ID of the account/contract which made the query
 * @param params.logs log output from a query execution response
 * @param params.prefix string to append to the beginning of each log
 */
function printTxOutcomeLogs({
  contractId,
  logs,
  prefix = ""
}) {
  for (const log of logs) {
    logger_1.Logger.log(`${prefix}Log [${contractId}]: ${log}`);
  }
}
exports.printTxOutcomeLogs = printTxOutcomeLogs;
export default exports;